export const googleTag = {
    install(Vue) {
      // Initialize Google Tag
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      window.gtag = gtag
  
      // Load Google Tag script
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16865687913'
      document.head.appendChild(script)
  
      // Basic configuration
      gtag('js', new Date())
      gtag('config', 'AW-16865687913')
  
      // Add to Vue instance
      Vue.gtag = gtag
      Vue.prototype.$gtag = gtag
    }
  }