// services/analytics.js
export const analyticsService = {
  // Utility methods
  isGtagLoaded() {
    return typeof window.gtag === 'function'
  },

  safeTrack(eventName, params = {}, sendTo = null) {
    try {
      if (!this.isGtagLoaded()) {
        console.warn('Google Tag not loaded')
        return
      }

      const eventParams = {
        ...params,
        ...(sendTo && { 'send_to': sendTo })
      }

      window.gtag('event', eventName, eventParams)

      if (process.env.NODE_ENV === 'development') {
        // console.log('Google Ads Event:', {
        //   event: eventName,
        //   parameters: eventParams
        // })
      }
    } catch (error) {
      console.error('Google Tag tracking error:', error)
    }
  },

  // Core tracking methods
  trackPageView(routeData) {
    this.safeTrack('page_view', {
      page_title: routeData.meta?.pageTitle || 'BRIKS',
      page_path: routeData.path
    })
  },

  trackSignUp(userData = {}) {
    this.safeTrack('ads_conversion_Sign_up_1', {
      user_type: userData.account || 'free',
      signup_method: userData.loginMethod || 'email',
      value: 1.0,
      currency: 'USD'
    }, 'AW-16865687913')
  },

  trackConversion(type, value = 0) {
    this.safeTrack('conversion', {
      value: value,
      currency: 'USD',
      transaction_id: crypto.randomUUID?.() || Date.now().toString()
    }, 'AW-16865687913')
  },

  // Additional tracking methods
  trackFeatureUsage(featureName, params = {}) {
    this.safeTrack('feature_usage', {
      feature_name: featureName,
      ...params
    })
  },

  trackError(errorType, errorDetails = {}) {
    this.safeTrack('error_occurred', {
      error_type: errorType,
      ...errorDetails
    })
  }
}

// Development mode logging
if (process.env.NODE_ENV === 'development') {
  console.log('Analytics Service: Development mode enabled')
}