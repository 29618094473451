/* plugins/fb-pixel.js */
export default {
    install(Vue) {
      // Create fbq if it doesn't exist
      if (typeof window.fbq === 'undefined') {
        window.fbq = function() {
          window.fbq.callMethod ? 
          window.fbq.callMethod.apply(window.fbq, arguments) : 
          window.fbq.queue.push(arguments)
        }
        window.fbq.push = window.fbq
        window.fbq.loaded = true
        window.fbq.version = '2.0'
        window.fbq.queue = []
      }
  
      // Initialize FB Pixel
      Vue.fbq = window.fbq
      Vue.prototype.$fbq = window.fbq
  
      // Load the Facebook Pixel script
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://connect.facebook.net/en_US/fbevents.js'
      document.head.appendChild(script)
  
      // Initialize your Pixel
      window.fbq('init', '1134522084797065')
      window.fbq('track', 'PageView')
    }
  }