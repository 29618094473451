import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import { analyticsService } from '@/services/analytics'


Vue.use(VueRouter)

const PAGE_CATEGORIES = {
  CREATE: ['create', 'agent-illustrations', 'agent-images', 'create-memes', 'create-quotes', 'create-agent', 'agent-page'],
  EXPLORE: ['explore-images', 'agents', 'products'],
  USER: ['profile-view', 'my-agents', 'My Collections', 'billings'],
  AUTH: ['login', 'signup'],
  PLANS: ['Plans'],
  HOME: ['index'],
  LEGAL: ['privacy-policy', 'termsandconditions', 'account-deletion']
}

// Analytics service
const getPageCategory = (routeName) => {
  return Object.entries(PAGE_CATEGORIES).find(([category, routes]) => 
    routes.includes(routeName)
  )?.[0] || 'OTHER'
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'index' } },
    ...dashboard,
  
    
    
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  if (to.meta.isAuthRequired) {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const isLoggedIn = userData && Object.keys(userData).length !== 0
    if (isLoggedIn) {
      next()
    } else {
      router.push('/index').catch(() => {})
    }
  }

  return next()
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.pageTitle || 'BRIKS';  // Use the meta pageTitle or default to 'BRIKS'
  next();
});

// Facebook Pixel tracking
router.afterEach((to, from) => {
  try {
    if (typeof window.fbq !== 'function') return

    // Basic PageView tracking
    window.fbq('track', 'PageView')

    // Get user data if available
    const userData = JSON.parse(localStorage.getItem('userData') || '{}')
    const pageCategory = getPageCategory(to.name)

    // Enhanced page tracking
    window.fbq('trackCustom', 'PageNavigate', {
      page_name: to.name,
      page_path: to.path,
      page_title: to.meta.pageTitle || 'BRIKS',
      page_category: pageCategory,
      previous_page: from.path,
      user_status: userData.userId ? 'logged_in' : 'guest',
      user_plan: userData.plan || 'none',
      user_account_type: userData.account || 'none'
    })

    // Track specific section views
    switch(pageCategory) {
      case 'CREATE':
        window.fbq('trackCustom', 'CreateToolView', {
          tool_type: to.name,
          page_path: to.path
        })
        break
      
      case 'EXPLORE':
        window.fbq('trackCustom', 'ExploreView', {
          section_type: to.name,
          page_path: to.path
        })
        break

      case 'USER':
        window.fbq('trackCustom', 'UserDashboardView', {
          section: to.name,
          user_plan: userData.plan || 'none'
        })
        break
    }

    // Special route tracking
    if (to.name === 'Plans') {
      window.fbq('track', 'ViewContent', {
        content_type: 'pricing_page',
        content_name: 'Subscription Plans'
      })
    }

    // Development logging
    if (process.env.NODE_ENV === 'development') {
      // console.log('FB Pixel Event:', {
      //   page: to.name,
      //   category: pageCategory,
      //   path: to.path
      // })
    }

  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Facebook Pixel tracking error:', error)
    }
  }
})

router.afterEach((to, from) => {
  // Track page views
  analyticsService.trackPageView(to)
})

export default router
